import styled from "@emotion/styled";

const CustomHeader = styled('header')`
    width: 100%;
    background-color: #ffffff;
`

const CustomHeaderContent = styled('section')`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 16px;
`

const Header = () => {
  return (
    <CustomHeader>
      <CustomHeaderContent>
        <a href="/">
          <img
            src="/logo-serasa-experian-color-1.png.webp"
            alt="logo"
            loading="lazy"
            width={140}
          />
        </a>
      </CustomHeaderContent>
    </CustomHeader>
  )
}

export default Header;